import React, {Component} from "react";
import '../App.css';
import ContactForm from "./ContactForm";
import saukko from "../assets/images/saukko.svg";
import insta_fc from "../assets/images/instagram_favi.svg";
import nc_favicon from "../assets/images/nc_faviconNL.ico";
import ruka from '../assets/images/Saukonmaja-kuva.png';
import ContactDetails from "./ContactDetails";
import Gallery from "./Gallery";
import skiing from '../assets/images/downhill_skiing_black_24dp.svg';
import link from '../assets/images/external-link-alt-solid.svg';
import hiking  from '../assets/images/hiking_black_24dp.svg';
import SecretSanta from "./SecretSanta";
import Signup from "./Signup";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.state = {
            isLoading: true,
            currentPage: 'home',
        };
        this.goToContactForm = this.goToContactForm.bind(this)
        this.goToContactDetails = this.goToContactDetails.bind(this)
        this.goToGallery = this.goToGallery.bind(this)
        this.goToHome = this.goToHome.bind(this)
        this.goToSecretSanta = this.goToSecretSanta.bind(this)
    }

    componentDidMount(){
        document.title = "Isopahkala"
    }

    goToContactDetails(e) {
        e.preventDefault()
        this.setState({
            currentPage: 'contactDetails'
        }, () => window.scrollTo(0, 0))
    }

    goToContactForm(e) {
        e.preventDefault()
        this.setState({
            currentPage: 'contactForm'
        }, () => window.scrollTo(0, 0))
    }

    goToGallery(e) {
        e.preventDefault()
        this.setState({
            currentPage: 'gallery'
        }, () => window.scrollTo(0, 0))
    }

    goToHome(e) {
        e.preventDefault()
        this.setState({
            currentPage: 'home'
        }, () => window.scrollTo(0, 0))
    }

    goToSecretSanta(e) {
        e.preventDefault()
        this.setState({
            currentPage: 'secretSanta'
        }, () => window.scrollTo(0, 0))
    }

    renderLink(text, onClick, className) {
        return <a
            href={'/'}
            onClick={(e) => onClick(e)}
            className={className}
        >
            {text}
        </a>
    }

    renderMain() {
        if (this.state.currentPage === 'home') {
            return this.renderHome()
        } else if (this.state.currentPage === 'contactForm') {
            return this.renderContactForm()
        } else if (this.state.currentPage === 'contactDetails') {
            return this.renderContactDetails()
        } else if (this.state.currentPage === 'gallery') {
            return this.renderGallery()
        } else if (this.state.currentPage === 'secretSanta') {
            return this.renderSecretSanta()
        } else {
            return this.renderHome()
        }
    }

    renderHome() {
        return <>
            <div className={'App-title'}>
                <p className={'App-title-text'}>Tervetuloa Jeren etusivulle!</p>
                <button className="App-title-button" onClick={this.goToContactForm}>Ota yhteyttä</button>
            </div>
            <div className={'App-box'}>
                <div className={'App-box-child'}>
                    <div className={'App-box-child-child'}>
                        <h2>
                            <img
                                src={skiing}
                                alt={'Skiing'}
                                width={30}
                                height={30}
                            /> Laskettelu
                        </h2>
                        <p>Rukalla ei tekeminen talvella lopu. Täydelliset laskettelu mahdollisuudet sekä bussi yhteydet läheltä mökkiä. Katso Rukan sivuilta lisää.</p>
                        <a className={'highlighted-link'} href={'https://www.ruka.fi/fi'}>Ruka.fi <img
                            src={link}
                            alt={'External link'}
                            width={16}
                            height={16}
                        /></a>
                    </div>
                    <div className={'App-box-child-child'}>
                        <h2>
                            <img
                                src={hiking}
                                alt={'Hiking'}
                                width={30}
                                height={30}
                            /> Vaellus
                        </h2>
                        <p>Rukalla on kesälle tarjolla monipuoliset vaellus reitit. Katso tarkemmat tiedot Rukan sivulta.</p>
                        <a className={'highlighted-link'} href={'https://www.ruka.fi/fi/elamykset/vaellus-ja-retkeily'}>Ruka.fi vaellus <img
                            src={link}
                            alt={'External link'}
                            width={16}
                            height={16}
                        /></a>
                    </div>
                </div>
                <div className={'App-box-child-2'}>
                    <img
                        className={'front-page-image'}
                        onClick={this.goToGallery}
                        src={ruka}
                        alt={'Jere'}
                    />
                    <h2>Jere</h2>
                </div>
            </div>
        </>
    }

    renderContactForm() {
        return <ContactForm/>
    }

    renderContactDetails() {
        return <ContactDetails goToContactForm={this.goToContactForm}/>
    }

    renderGallery() {
        return <Gallery/>
    }

    renderSecretSanta() {
        return <SecretSanta/>
    }

    render() {
        return (
            <>
                <div className="App">
                    <div className={'App-header'}>
                        <div className={'App-header-bar'}>
                            <div className={'App-header-home'}>
                                {this.renderLink('Jere', this.goToHome)}
                            </div>
                            <div className={'App-header-items'}>
                                {/*this.renderLink('Yhteystiedot', this.goToContactDetails)*/}
                                {this.renderLink('Secret Santa', this.goToSecretSanta)}
                                {/*
                                {this.renderLink('Galleria', this.goToGallery)}
                                */}
                                <button className="App-header-button" onClick={this.goToContactForm}>Ota yhteyttä</button>
                            </div>
                        </div>
                        {this.renderMain()}
                        <footer>
                            <div className={'App-footer-box'}>
                                <img className={'saukko'} src={saukko} alt={'Saukko'}/>
                                <h2 className={'footer'}>Jere</h2>
                            </div>
                            <div className={'App-footer-box'}>
                                <br/>
                                <a href={'https://www.instagram.com/saukonmaja/'} rel={'noreferrer'} target={'_blank'} className={'footer'}><img width={16} height={16} src={insta_fc} alt={'Instagram Saukonmaja'}/> Saukonmaja</a>
                                <br/>
                                <a href={'https://www.nettimokki.com/kuusamo/9716'} rel={'noreferrer'} target={'_blank'} className={'footer'}><img src={nc_favicon} alt={'Nettimökki'}/> Nettimökki</a>
                            </div>
                            <div className={'App-footer-box'}>
                                <br/>
                                {this.renderLink('Yhteystiedot', this.goToContactDetails, 'footer')}
                                <br/>
                                {this.renderLink('Ota yhteyttä', this.goToContactForm, 'footer')}
                                <br/>
                                {/*
                                {this.renderLink('Galleria', this.props.goToGallery, 'footer')}
                                */}
                            </div>
                        </footer>
                    </div>
                </div>
            </>
        );
    }
}
