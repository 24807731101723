import React, { Component } from "react";
import {Auth} from "aws-amplify";
import {Link} from "react-router-dom";

export default class Signup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            email: 'jere.isopahkala+mypooltest@gmail.com',
            name: "",
            password: "Salasana1234",
            confirmPassword: "",
            confirmationCode: "",
            newUser: null,
            hiliteEmail: false
        }
    }

    validateForm() {
        return (
            this.state.email &&
            this.state.password
        )
    }

    handleKeyDown =(evt)=> {
        if (evt.key === 'Enter'){
            evt.preventDefault()
            this.handleSubmit()
        }
    }

    handleChange = (id, val) => {
        console.log(val)
        this.setState({
            [id]: val
        })
    }

    async continueToConfirmCode(resendCode) {
        let ok = resendCode
        if (ok) {console.log('asd')
        }
    }

    handleSubmit = async ()=> {
        this.setState({ isLoading: true })
        try {
            let params = {
                username: this.state.email,
                password: this.state.password,
            }
            const newUser = await Auth.signUp(params)
            this.setState({
                newUser
            })
        } catch (e) {
            if (e.message.indexOf("email already exists") > 0) {
                console.log('email already exists')
            }else{
                console.log(e.message)
            }
        }
        this.setState({ isLoading: false })
    }

    handleConfirmationSubmit = async () => {
        this.setState({ isLoading: true })
        try {
            console.log('Sign-up confirmation', this.state.email, this.state.confirmationCode);
            await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
            console.log('Sign-in', this.state.email);
            await Auth.signIn(this.state.email, this.state.password);

            // set email into client app
        } catch (e) {
            console.log(e.message)
            this.setState({ isLoading: false })
        }
    };

    async resendConfirmationCode() {
        this.setState({
            isLoading: true
        })
        let result
        try  {
            await Auth.resendSignUp(this.state.email)
            console.log("Vahvistuskoodi tilattu")
            result = true
        } catch (e) {
            console.log('resendConfirmationCode exception', e.message)
            console.log(e.message + ". Jos olet jo rekisteröitynyt valitse 'unohdin salasanan'")
            result = false
        }
        this.setState({ isLoading: false })
        return result
    }

    render() {
        return <>
            <div className={'login-box'}>
                <input
                    className={'login'}
                    id={"email"}
                    type={"email"}
                    value={this.state.email}
                    onChange={(val)=> this.handleChange('email', val.target.value)}
                />
                <input
                    id={"password"}
                    type={"password"}
                    value={this.state.password}
                    onChange={(val)=> this.handleChange('password', val)}
                />
                <input
                    id={"confirmationCode"}
                    type={"number"}
                    inputMode={"numeric"}
                    value={this.state.confirmationCode}
                    onChange={(val)=> this.handleChange('confirmationCode', val)}
                />
                <button onClick={() => this.handleSubmit()}>Uusi käyttäjä</button>
                <button onClick={() => this.resendConfirmationCode()}>Uusi koodi</button>
                <button onClick={() => this.handleConfirmationSubmit()}>Vahvistakoodilla</button>
            </div>
        </>
    }
}
