import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify from "aws-amplify"
import {Auth} from "aws-amplify"

const aws = {
    Auth: {
        mandatorySignIn: true,
        region: "eu-central-1",
        userPoolId: 'eu-central-1_TkWmEqD4H',
        //identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: '7itpces2v0qlc59p7asovmc0dd',
        authenticationFlowType: 'USER_SRP_AUTH'    // default 'USER_SRP_AUTH' 2023-8-30 USER_PASSWORD_AUTH
    },
    API: {
        endpoints: [
            {
                name: "isopahkala",
                endpoint: "https://dxzf18nhr1.execute-api.eu-central-1.amazonaws.com/prod",
                region: "eu-central-1"
            },
        ]
    }
}

Amplify.configure(aws)
Auth.configure(aws)

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
