import React, {Component} from "react";
import '../App.css';

export default class SecretSanta extends Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.state = {
            isLoading: true,
            email_address: '',
            phone_number: '',
            message: '',
        };
    }

    render() {
        return (
            <>
                <div style={{height: '110svh', width: '80svh', left: '10svh', top: '20svh'}}> </div>
                <div style={{position: 'fixed', height: '80svh', width: '80%', left: '10%', top: '20svh', backgroundColor: 'orange', opacity: '80%'}}>
                    <div className={'santa-option'} style={{position: 'fixed'}}><b>A</b></div>
                </div>
            </>
        );
    }
}
